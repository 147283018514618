const news = [
	{
		title: "American Radio Station Interview",
		text: "Cras eu diam hendrerit, varius ipsum quis, efficitur diam. In aliquet felis sapien, pharetra vulputate neque imperdiet vel. Proin dictum mollis efficitur. Nam nisl ipsum, mollis ut turpis eget, semper congue sem. Maecenas nibh libero, pulvinar aliquet venenatis ac, finibus quis leo. In ullamcorper eu neque eu ultrices. Curabitur a interdum massa. Vestibulum sit amet tempor dui, id maximus velit. Mauris orci metus, gravida vel sem eget, pulvinar bibendum nibh. Sed maximus est in augue blandit, at pellentesque tortor pharetra. Cras nisl nulla, venenatis fermentum congue ac, convallis nec nibh. Vestibulum tristique at arcu id condimentum. Nunc eget ligula non lorem scelerisque ornare malesuada sit amet urna. Etiam velit orci, accumsan eget massa id, tristique finibus libero.",
		external_url: 'https://www.avaliveradio.info/',
		iframe_url: 'https://www.youtube.com/embed/videoseries?list=PLHpXWASutEYhkc8CfFT-_BpFlWgIclxaL'
	},
	{
		title: "New EP",
		text: "Cras eu diam hendrerit, varius ipsum quis, efficitur diam. In aliquet felis sapien, pharetra vulputate neque imperdiet vel. Proin dictum mollis efficitur. Nam nisl ipsum, mollis ut turpis eget, semper congue sem. Maecenas nibh libero, pulvinar aliquet venenatis ac, finibus quis leo. In ullamcorper eu neque eu ultrices. Curabitur a interdum massa. Vestibulum sit amet tempor dui, id maximus velit. Mauris orci metus, gravida vel sem eget, pulvinar bibendum nibh. Sed maximus est in augue blandit, at pellentesque tortor pharetra. Cras nisl nulla, venenatis fermentum congue ac, convallis nec nibh. Vestibulum tristique at arcu id condimentum. Nunc eget ligula non lorem scelerisque ornare malesuada sit amet urna. Etiam velit orci, accumsan eget massa id, tristique finibus libero.",
		external_url: null,
		iframe_url: 'https://open.spotify.com/embed/track/35C98wbvyi8VGCbvsfJ2S0'
	}
]

export {news}
