<template>
  <div class="absolute top-0 w-screen h-screen flex items-center justify-center z-10">
    <div
        class="news-container flex flex-col items-center mx-4 py-4 lg:py-10 gap-5 lg:gap-10 2xl:gap-24 w-full sm:w-2/5 overflow-x-hidden overflow-y-auto"
        style="height: 70vh">
      <div v-for="(newsItem, i) in news" :key="i" class="w-full text-white">
        <h2 class="text-xl font-bold underline">{{ newsItem.title }}</h2>

        <p class="text-lg mt-3 mb-5">{{ newsItem.text }}</p>

        <div class="flex gap-4 mb-4">
          <a :href="newsItem.external_url" v-if="newsItem.external_url" target="_blank" class="news-link">More details</a>
          <button v-if="newsItem.iframe_url" @click="newsItem.showIframe = !newsItem.showIframe" class="news-link">Play</button>
        </div>

        <div class="relative w-full" style="height: 40vh"
             v-if="!!newsItem.iframe_url && !!newsItem.showIframe">
          <iframe :src="newsItem.iframe_url" class="w-full h-full" allowtransparency="true" allow="encrypted-media"
                  allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {news} from '@/news'

export default {
  name: 'News',
  data() {
    return {
      news: news
    }
  }
}
</script>

<style scoped lang="postcss">
  .news-link {
    @apply rounded-bl-lg border-l-2 border-b-2 border-white px-2 text-sm font-semibold uppercase transition;

    &:hover {
      @apply border-orange-400 text-orange-400;
    }
  }
</style>
